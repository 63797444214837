import React from "react"
import { Layout } from "@/layout"

import { Box, CTA, SEO, Width, Heading, Text } from "@/components/index"
import { color } from "@/theme/index"

export default function Fellowship({ data }) {
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="You've been accepted!"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
        noindex={true}
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={"9.6rem"}
        pb={["4.8rem", null, null, "0"]}
      >
        <Heading level="1" mt="5rem" mb="2rem">
          🎉 You’ve been accepted! 🎉
        </Heading>
        <Box
          width={["100%", null, 12 / 12]}
          mx="auto"
          textAlign="center"
          mb={["6rem", null, null, "0"]}
          px={["0", "0"]}
        >
          <Box width={["100%", null, 6 / 12]} mx="auto" mb="3em">
            <Text mb="2.3rem" p="1rem">
              Congratulations on being accepted into the{" "}
              <strong>Mentorship Program! </strong>
              You've put in a lot of work to get to this level on guitar and it
              shows. We're excited to work with you!{" "}
            </Text>
          </Box>{" "}
          <Width display="flex" flexDirection={["column", "row"]}>
            <Box
              width={["100%", null, 6 / 12, null]}
              pr={[null, null, "1rem", null]}
              textAlign="left"
            >
              <Text mb="2.3rem">
                We completely understand that you need a bit of time to think
                about the decision, so we’ve put together this page for you to
                explore the program details.
              </Text>
              <Text mb="2.3rem">
                <strong>
                  We’ve also included the orientation series from the program!
                </strong>
              </Text>
              <Text mb="2.3rem">
                {" "}
                It breaks down everything we teach our students about
                accelerated learning and describes our approach in a nutshell.
              </Text>
              <Box
                width={["100%", "80%", 12 / 12, null]}
                p="2.4rem"
                textAlign="center"
                mb={"2.4rem"}
                bg="#FFF7F4"
                borderRadius=".6rem"
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  display="flex"
                  mb="0.6rem"
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Text fontWeight="bold" color="amethyst">
                    What is Sonora Guitar Intensive?
                  </Text>
                </Box>
                <Text level={2}>
                  At Sonora Guitar Intensive, we've helped over a thousand
                  players break through the{" "}
                  <strong>intermediate guitar plateau</strong> in a simple and
                  systematic way, under the direct instruction of some of the{" "}
                  <strong>best guitar players in the world.</strong>
                </Text>
                <CTA to="/" variant="primary" mt="1.5rem">
                  Learn more
                </CTA>
              </Box>
            </Box>
            <Box
              width={["100%", "80%", 6 / 12, null]}
              p="2.4rem"
              textAlign="left"
              mb={"2.4rem"}
              bg={color.opal}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={"left"}
              >
                <Text fontWeight="bold" color="amethyst">
                  Read some reviews
                </Text>
              </Box>
              <Text level={2}>
                Our alumni love us! Check out these reviews to read what they have to say.
              </Text>
              <CTA to="https://www.google.com/maps/place/Sonora+Guitar+Intensive/@45.5980213,-127.2696389,3z/data=!4m7!3m6!1s0x80c287e94251dc61:0x99e6bedd44abb135!8m2!3d45.5980213!4d-127.2696389!9m1!1b1" variant="special" mt="1.5rem">
                Read Reviews
              </CTA>
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={"left"}
                mt="3rem"
              >
                <Text fontWeight="bold" color="amethyst">
                  Watch the orientation series
                </Text>
              </Box>
              <Text level={2}>
                This is the first module from our program and it will teach you
                everything you need to know about accelerated learning,
                deliberate practice and our teaching methodology.
              </Text>
              <CTA to="/orientation" variant="special" mt="1.5rem">
                Watch Now
              </CTA>
              <Box
                display="flex"
                mb="0.6rem"
                mt="3rem"
                alignItems="center"
                justifyContent={"left"}
              >
                <Text fontWeight="bold" color="amethyst">
                  Browse the mentors
                </Text>
              </Box>
              <Text level={2}>
                At Sonora, we offer students the opportunity to study one on one
                with some of the <strong>best guitarists in the world</strong>.
              </Text>
              <CTA to="/mentorship" variant="primary" mt="1.5rem">
                Browse Mentors
              </CTA>
              <Box
                display="flex"
                mb="0.6rem"
                mt="3rem"
                alignItems="left"
                justifyContent={"left"}
              >
                <Text fontWeight="bold" color="amethyst">
                  Preview of the learning software
                </Text>
              </Box>
              <Text level={2}>
                Our industry leading accelerated learning software is a huge
                component of what helps students achieve remarkable results in a
                short period of time.
              </Text>
              <CTA
                to="https://www.loom.com/share/c743b7d28dde4c7283162b5d27c2e886"
                variant="primary"
                mt="1.5rem"
                mr="1em"
              >
                Watch Demo
              </CTA>
            </Box>
          </Width>
          <Box p="1em">
            <Box
              width={[12 / 12, null, 5 / 12, null]}
              p="2.4rem"
              textAlign="center"
              mb={"2.4rem"}
              bg={color.tanzanite}
              color="#fff"
              borderRadius=".6rem"
              mx="auto"
              mt="2em"
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={"center"}
              >
                <Text fontWeight="bold" color="thulite">
                  Want to see more?
                </Text>
              </Box>
              <Text level={2}>
                We post daily guitar wisdom and snippets from our masterclasses
                on Instagram and Youtube.
              </Text>
              <CTA
                to="http://instagram.com/sonoraguitarintensive"
                variant="tertiary"
                mt="1.5rem"
                mr="1em"
              >
                Instagram
              </CTA>
              <CTA
                to="https://www.youtube.com/channel/UC5uW_LRRowhYZDdHvzTkEHA"
                variant="tertiary"
                mt="1.5rem"
              >
                Youtube
              </CTA>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
